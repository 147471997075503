import React,{useEffect} from 'react';
import styled from 'styled-components';
import { connect } from "react-redux";
import { imgList } from './../constants/ImagesList';

export default (props) => {
    return (
        <Content className="footer">
            <div className="footer__top">
                <div className="footer__left">
                    <div className="footer__regular">{props.title}</div>
                    <div className="footer__light">© 2020 Copyright TIME Consulting Co.,Ltd.</div>
                </div>
                <div className="footer__right">
                    <div className="footer__medium">Contact Us</div>
                    <div className="footer__text">89 อาคาร AIA Capital Center ชั้น 10 และ 15 ถ.รัชดาภิเษก แขวงดินแดง เขตดินแดง กรุงเทพฯ 10400 โทร. 0809564595, 020039778 อีเมล์ contact@timeconsulting.co.th</div>
                </div>
            </div>
            <div className="footer__bottom"></div>
        </Content>
    )
}

const Content = styled.div`
    position: relative;
    width: 100%;
    background: green;
    .footer{
        &__top{
            width: 100%;
            min-height: 170px;
            background: #0F3492;
            display: flex;
            align-items: center;
            padding: 2% 6%;
            @media screen and (orientation: portrait) , (max-width: 850px){
                flex-direction: column-reverse;
                justify-content: center;
                padding: 4% 6%;
            }
        }
        &__bottom{
            width: 100%;
            height: 48px;
            background: #707070;
        }
        &__left{
            width: 70%;
            @media screen and (orientation: portrait) , (max-width: 850px){
                width: 100%;
                text-align: center;
            }
        }
        &__right{
            width: 30%;
            @media screen and (orientation: portrait) , (max-width: 850px){
                width: 100%;
                text-align: center;
                margin-bottom: 5%;
            }
        }
        &__regular{
            font-family: 'Poppins-Regular', 'tahoma';
            line-height: 1;
            color: #fff;
            font-size: 1.2em;
            margin-bottom: 2%;
        }
        &__light{
            font-family: 'Poppins-Light', 'tahoma';
            line-height: 1;
            color: #fff;
            font-size: .8em;
        }
        &__medium{
            font-family: 'Poppins-Medium', 'tahoma';
            color: #fff;
            font-size: .8em;
        }
        &__text{
            font-family: 'Kanit-Light', 'tahoma';
            color: #fff;
            font-size: .7em;
            word-break: break-word;
        }
    }
`;