import React,{useEffect,useState} from 'react';
import styled from 'styled-components';
import { connect } from "react-redux";
import { imgList } from './../constants/ImagesList';

const Intro = props => {
    return (
        <Content>
            <div className="content__detail">
                <div className="content__head">{props.surveyInfo.name.en}</div>
                <div className="content__sub-head">{props.surveyInfo.name.th}</div>
                <div className="content__text">ประกอบด้วย {props.surveyInfo.dimensions_count} ส่วน</div>
            </div>
            <div className="content__detail-2">
                <div className="content__detail-2-inner">
                    {props.surveyInfo.dimensions && props.surveyInfo.dimensions.length>0 && props.surveyInfo.dimensions.map((item, key) => {
                        return (
                            <Blog className="blog" src={item.cover&&item.cover.original} key={"home_"+key}>
                                <div className="blog__img"/>
                                <div className="blog__detail">
                                    <div className="blog__head">{item.title.th}</div>
                                    <div className="blog__title">{item.title.en}</div>
                                </div>
                            </Blog>
                        );
                    })}
                </div>
                <div className="content__btn" onClick={()=>props.handleStart(1)}>
                    เริ่มการประเมิน
                </div>
            </div>
        </Content>
    )
}

const mapStateToProps = state => ({
    ...state.Main,
    ...state.Modal
});

export default connect(
    mapStateToProps
)(Intro);

const Content = styled.div`
    width: 100%;
    background: #fff;
    @media screen and (orientation: portrait) , (max-width: 850px){
        flex-direction: column-reverse;
    }
    .content{
        &__detail{
            width: 100%;
            padding: 5% 6%;
            display: flex;
            flex-direction: column;
            justify-content: center;
            box-shadow: 0px 3px 6px #00000029;
            background: #F8F9FA;
            @media screen and (orientation: portrait) , (max-width: 850px){
                padding: 8%;
                text-align: center;
            }
        }
        &__detail-2{
            width: 100%;
            padding: 4% 5%;
            @media screen and (orientation: portrait) , (max-width: 850px){
                padding: 8%;
                text-align: center;
            }
        }
        &__head{
            font-family: 'Poppins-Medium', 'tahoma';
            font-size: 1.6em;
            color: #ED572C;
            line-height: 1;
            margin-bottom: 2%;
            @media screen and (orientation: portrait) , (max-width: 850px){
                margin-bottom: 5%;
            }
        }
        &__sub-head{
            font-size: 1.5em;
            color: #4E5456;
            line-height: 1;
            margin-bottom: 2%;
            @media screen and (orientation: portrait) , (max-width: 850px){
                margin-bottom: 5%;
            }
        }
        &__text{
            font-family: 'Kanit-Light', 'tahoma';
            font-size: 1em;
            color: #4E5456;
        }
        &__detail-2-inner{
            width: 100%;
            display: flex;
            align-items: inherit;
            justify-content: center;
            @media screen and (max-width: 1280px){
                flex-wrap: wrap;
                max-width: 800px;
                margin: 0 auto;
            }
        }
        &__btn{
            background: #ED592F;
            color: #fff;
            font-size: 1em;
            text-align: center;
            width: 8em;
            border-radius: 1.2em;
            padding: .5em 0;
            margin: 3% auto 0;
            display: block;
            cursor: pointer;
            transition: .3s opacity;
            &:hover{
                opacity: .9;
            }
        }
    }
`;
const Blog = styled.div`
    width: 18%;
    margin: 0 1% 2%;
    box-shadow: 0px 3px 6px #00000029;
    @media screen and (max-width: 1280px){
        width: calc(94%/3);
    }
    @media screen and (max-width: 850px){
        width: calc(96%/2);
    }
    @media screen and (max-width: 450px){
        width: calc(98%);
        margin: 0 0 10%;
    }
    .blog{
        &__img{
            width: 100%;
            padding-top: calc(100%*(282/608));
            background: url(${props=>props.src}) no-repeat center;
            background-size: cover;
        }
        &__detail{
            padding: 8% 10%;
        }
        &__head{
            font-family: 'Kanit-Regular', 'tahoma';
            color: #4E5456;
            font-size: 1em;
            text-align: center;
        }
        &__title{
            font-family: 'Poppins-Regular', 'tahoma';
            color: #4E5456;
            font-size: .9em;
            text-align: center;
        }
    }
`