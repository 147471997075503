import React,{useEffect,useState} from 'react';
import styled from 'styled-components';
import { connect } from "react-redux";
import { imgList } from './../constants/ImagesList';
import { setValues } from '../store/redux';
import { setValues as setModalValues } from '../features/modal/redux';

const Checkbox = props => {
    const [change, setChange] = useState(false);
    var cnt = 0;
    const handleChoose = (key,index) =>{
        var a = Object.assign(props.assessmentInfo&&props.assessmentInfo.answers);
        console.log("first",a);
        let indexFind = a[key].indexOf(index);
        console.log("find",indexFind);
        if(indexFind>-1){
            a[key].splice(indexFind, 1);
            console.log("slice",a);
        }
        else{
            a[key] = [...a[key],index];
        }
        props.setValues({
            assessmentInfo: {
                ...props.assessmentInfo,
                answers: a
            }
        })
        setChange(!change);
    }
    return (
        <Content className="checkbox">
            {props.assessmentInfo && props.assessmentInfo.topics && props.assessmentInfo.topics.length>0 && props.assessmentInfo.topics.map((item, key) => {
                return (
                    <React.Fragment key={"key1"+key}>
                        <div className="checkbox__type">
                            <div className="checkbox__head-2">{item.topic_name}</div>
                        </div>
                        <div className="checkbox__exam">
                            {item.question_lists.length>0 && item.question_lists.map((item_list, key_list) => {
                                cnt=cnt+1;
                                let keyC = cnt;
                                let checked = props.assessmentInfo&&props.assessmentInfo.answers[keyC-1];
                                return (
                                    <div className="checkbox__row" key={"key_list"+key_list} id={"exam_"+key_list}>
                                        <div className="checkbox__head-3" dangerouslySetInnerHTML={{__html: item_list.order+". "+item_list.title}}></div>
                                        <div className="checkbox__level-2">
                                            {item_list.options.length>0 && item_list.options.map((item_choice, key_choice) => {
                                                return (
                                                    <div key={"choice_"+key_choice}>
                                                        <Btn width="100" height="100" img={imgList.btn_checkbox} checked={checked.indexOf(key_choice)>=0?true:false} onClick={()=>handleChoose(keyC-1,key_choice)}/>
                                                        <div onClick={()=>handleChoose(keyC-1,key_choice)} dangerouslySetInnerHTML={{__html: item_choice}}></div>
                                                    </div>
                                                );
                                            })}
                                        </div>
                                    </div>
                                );
                            })}
                        </div>
                    </React.Fragment>
                );
            })}
        </Content>
    )
}

const mapStateToProps = state => ({
    ...state.Main,
    ...state.Modal
});

const mapDispatchToProps = { setValues, setModalValues };
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Checkbox);

const Content = styled.div`
    width: 100%;
    .checkbox{
        &__head-1{
            color: #0F3492;
            font-size: 1.1em;
            font-family: 'Kanit-Bold', 'tahoma';
            margin: 1em 0;
        }
        &__head-2{
            color: #fff;
            font-size: .9em;
            font-family: 'Kanit-Regular', 'tahoma';
            width: 100%;
            @media screen and (max-width: 850px){
               text-align: center;
            }
        }
        &__head-3{
            color: #4E5456;
            font-size: .9em;
            font-family: 'Kanit-Regular', 'tahoma';
            width: 100%;
            margin-bottom: .5em;
        }
        &__type{
            padding: .3em 1em;
            background: #0F3492;
            width: 100%;
            display: flex;
            align-items: center;
            margin-top: 1em;
            @media screen and (max-width: 850px){
                margin-bottom: 0;
                padding: 6%;
            }
        }
        &__type-2{
            width: 100%;
            padding: 0 1em;
        }
        &__exam{
            width: 100%;
            // padding: 1em 0em;
            background: #fff;
            @media screen and (max-width: 850px){
                padding: 3% 0;
            }
            >div{
                &:nth-child(odd){
                    background: #fff;
                }
                &:nth-child(even){
                    background: #F8F9FA;
                }
            }
        }
        &__row{
            width: 100%;
            padding: .5em 1em;
            @media screen and (max-width: 850px){
                padding: 1em;
            }
        }
        &__level{
            width: 50%;
            display: flex;
            align-items: center;
            margin-left: auto;
            >div{
                width: calc(90%/5);
                margin: 0 1%;
                color: #0F3492;
                font-size: .9em;
                font-family: 'Kanit-Regular', 'tahoma';
                text-align: center;
            }
            @media screen and (max-width: 850px){
                display: none;
            }
        }
        &__level-2{
            width: 100%;
            >div{
                display: flex;
                // align-items: center;
                align-items: flex-start;
                width: 100%;
                margin: 0 1% .5em;
                text-align: center;
                >canvas{
                    width: 1.5em;
                }
                >div{
                    color: #707070;
                    font-size: .9em;
                    margin-left: .5em;
                    cursor: pointer;
                    text-align: left;
                }
            }
            @media screen and (max-width: 850px){
                >div{
                    width: 100%;
                    margin: 1% 0;
                }
            }
        }
    }
`;

const Btn = styled.canvas`
    background: url(${props=>props.img}) no-repeat;
    background-size: cover;
    cursor: pointer;
    ${props=>props.checked
    ?
        `
            background-position: bottom center;
        `
    :
        `
            background-position: top center;
        `
    }
`;