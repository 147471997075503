import React,{useEffect,useState} from 'react';
import styled from 'styled-components';
import { connect } from "react-redux";
import { imgList } from './../constants/ImagesList';
import { setValues } from '../store/redux';
import { setValues as setModalValues } from '../features/modal/redux';
import {getCookie} from './../constants/Cookie';
import Header from './../features/header';
import Footer from './../features/footer';
import Intro from './../features/intro';
import Do from './../features/do';
import Result from './../features/result';
import Swal from 'sweetalert2';
import _ from 'lodash';
import $ from 'jquery';

import {
    LoadingModal
} from '../features/modal';

const Home = props => {
    const [loadData, setLoadData] = useState(false);
    const [step, setStep] = useState('intro');
    useEffect(() => {
        if(props.match.params.slug==='login' || props.match.params.slug==='result' || props.match.params.slug==='assessment'){
            window.location.href = '/';
            return;
        }
        if(getCookie(process.env.REACT_APP_JWT_COOKIE_NAME) === ''){
            window.location.href = '/'+props.match.params.slug+'/login';
        }
        else{
            apiGetInfo();
        }
        // apiGetInfo();
    }, []);
    const apiGetInfo = () =>{
		props.setValues({
			modal_open: 'loading'
		})
		fetch(process.env.REACT_APP_API_SERVER_HOST + process.env.REACT_APP_API_INDEX, {
			mode: 'cors',
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
				'credentials': 'include',
            }
		}).then(response =>{
            if (response.status == 200) {
                response.json().then(response => {
                    props.setValues({
						userInfo: response.data.userInfo
                    })
                    apiGetSurvey();
                });
            }
            else{
                throw new Error(response.status);
            }
        }).catch(error => {
			console.log('API Error : ', error)
        })
    }
    const apiGetSurvey = () =>{
        let slug = props.match.params.slug;
        props.setModalValues({
            modal_open: 'loading'
        })
        fetch(process.env.REACT_APP_API_SERVER_HOST + process.env.REACT_APP_API_SURVEY + '?slug='+slug, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json'
        }
        }).then(response =>{
            if (response.status == 200) {
                response.json().then(response => {
                    if(response.status){
                        props.setValues({
                            ...response.data
                        })
                        props.setModalValues({
                            modal_open: ''
                        })
                        if(response.data.surveyInfo.canSurvey){
                            if(response.data.surveyInfo.isPassed || response.data.surveyInfo.nextDimension>response.data.surveyInfo.dimensions_count){
                                apiGetResult();
                            }
                            else{
                                if(response.data.surveyInfo.nextDimension>1){
                                    apiGetAss(response.data.surveyInfo.nextDimension);
                                }
                                else{
                                    setStep('intro');
                                    setLoadData(true);
                                }
                            }
                        }
                        else{
                            Swal.fire({
                                icon: 'error',
                                title: 'แจ้งเตือน',
                                text: 'ไม่พบข้อมูลแบบสอบถาม',
                                confirmButtonColor: '#0F3492',
                            }).then((result) => {
                                if (result.value) {
                                    handleLogout();
                                }
                            })
                        }
                    }
                    else{
                        props.setModalValues({
                            modal_open: ''
                        })
                        if(response.error==='not_found'){
                            Swal.fire({
                                icon: 'error',
                                title: 'แจ้งเตือน',
                                text: 'ไม่พบข้อมูลแบบสอบถาม',
                                confirmButtonColor: '#0F3492',
                            })
                        }
                        else{
                            Swal.fire({
                                icon: 'error',
                                title: 'แจ้งเตือน',
                                text: response.message,
                                confirmButtonColor: '#0F3492',
                            }).then((result) => {
                                if (result.value) {
                                    handleLogout();
                                }
                            })
                        }
                    }
                });
            }
            else{
                console.log('API Error Network: ', response.error)
                props.setModalValues({
                    modal_open: ''
                })
                Swal.fire({
                    icon: 'error',
                    title: 'แจ้งเตือน',
                    text: 'เกิดข้อผิดพลาด กรุณาลองใหม่อีกครั้ง',
                    confirmButtonColor: '#0F3492',
                })
            }
        }).catch(error => {
            console.log('API Error : ', error)
        })
    }
    const apiGetAss = (index) =>{
        let slug = props.match.params.slug;
        props.setModalValues({
            modal_open: 'loading'
        })
        fetch(process.env.REACT_APP_API_SERVER_HOST + process.env.REACT_APP_API_ASSESSMENT + '?slug='+slug+'&dimension='+index, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json'
        }
        }).then(response =>{
            if (response.status == 200) {
                response.json().then(response => {
                    if(response.status){
                        props.setValues({
                            ...response.data
                        })
                        props.setModalValues({
                            modal_open: ''
                        })
                        setStep('do');
                        setLoadData(true);
                        window.scrollTo({ top: 0, behavior: 'smooth' });
                    }
                    else{
                        props.setModalValues({
                            modal_open: ''
                        })
                        Swal.fire({
                            icon: 'error',
                            title: 'แจ้งเตือน',
                            text: response.message,
                            confirmButtonColor: '#0F3492',
                        })
                    }
                });
            }
            else{
                console.log('API Error Network: ', response.error)
                props.setModalValues({
                    modal_open: ''
                })
                Swal.fire({
                    icon: 'error',
                    title: 'แจ้งเตือน',
                    text: 'เกิดข้อผิดพลาด กรุณาลองใหม่อีกครั้ง',
                    confirmButtonColor: '#0F3492',
                })
            }
        }).catch(error => {
            console.log('API Error : ', error)
        })
    }
    const apiGetResult = () =>{
        let slug = props.match.params.slug;
        props.setModalValues({
            modal_open: 'loading'
        })
        fetch(process.env.REACT_APP_API_SERVER_HOST + process.env.REACT_APP_API_RESULT + '?slug='+slug, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json'
        }
        }).then(response =>{
            if (response.status == 200) {
                response.json().then(response => {
                    if(response.status){
                        props.setValues({
                            ...response.data
                        })
                        props.setModalValues({
                            modal_open: ''
                        })
                        setStep('result');
                        setLoadData(true);
                    }
                    else{
                        props.setModalValues({
                            modal_open: ''
                        })
                        Swal.fire({
                            icon: 'error',
                            title: 'แจ้งเตือน',
                            text: response.message,
                            confirmButtonColor: '#0F3492',
                        })
                    }
                });
            }
            else{
                console.log('API Error Network: ', response.error)
                props.setModalValues({
                    modal_open: ''
                })
                Swal.fire({
                    icon: 'error',
                    title: 'แจ้งเตือน',
                    text: 'เกิดข้อผิดพลาด กรุณาลองใหม่อีกครั้ง',
                    confirmButtonColor: '#0F3492',
                })
            }
        }).catch(error => {
            console.log('API Error : ', error)
        })
    }
    const apiSubmit = (key) =>{
        let a = props.assessmentInfo&&props.assessmentInfo.answers;
        let check = false;
        for (let i = 0; i < a.length; i++) {
            if(_.isEmpty(a[i])){
                check = true;
                $('html, body').animate({scrollTop: $('#exam_'+i).offset().top -200 }, 'slow');
                break;
            }
        }
        if(check){
            return;
        }
        let apiSlug = props.match.params.slug;
        let apiDimension = key;
        let apiAnswers = props.assessmentInfo&&props.assessmentInfo.answers;
        props.setModalValues({
            modal_open: 'loading'
        })
        fetch(process.env.REACT_APP_API_SERVER_HOST + process.env.REACT_APP_API_SUBMIT, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({
            slug: apiSlug,
            dimension: apiDimension,
            answers: apiAnswers
        })
        }).then(response =>{
            if (response.status == 200) {
                response.json().then(response => {
                    if(response.status){
                        if(response.data.assessmentInfo.result){
                            props.setValues({
                                assessmentInfo:{
                                    ...props.assessmentInfo,
                                    ...response.data.assessmentInfo
                                },
                                resultInfo: response.data.resultInfo
                            })
                            setStep('result');
                            window.scrollTo({ top: 0, behavior: 'smooth' });
                        }
                        else{
                            props.setValues({
                                assessmentInfo:{
                                    ...props.assessmentInfo,
                                    ...response.data.assessmentInfo
                                }
                            })
                            window.scrollTo({ top: 0, behavior: 'smooth' });
                        }
                        props.setModalValues({
                            modal_open: ''
                        })
                    }
                    else{
                        props.setModalValues({
                            modal_open: ''
                        })
                        Swal.fire({
                            icon: 'error',
                            title: 'แจ้งเตือน',
                            text: response.message,
                            confirmButtonColor: '#0F3492',
                        })
                    }
                });
            }
            else{
                console.log('API Error Network: ', response.error)
                props.setModalValues({
                    modal_open: ''
                })
                Swal.fire({
                    icon: 'error',
                    title: 'แจ้งเตือน',
                    text: 'เกิดข้อผิดพลาด กรุณาลองใหม่อีกครั้ง',
                    confirmButtonColor: '#0F3492',
                })
            }
        }).catch(error => {
            console.log('API Error : ', error)
        })
    }
    const handleBack = (key) =>{
        if(key<1){
            return;
        }
        apiGetAss(key-1);
    }
    const handleStart = (index) =>{
        apiGetAss(index);
    }
    const handleLogout = () =>{
        props.setValues({doLogout: true})
    }
    return (
        <Page className="home">
            {loadData &&
                <>
                    <Header
                        name={props.userInfo.name}
                        title={props.surveyInfo.name.en}
                        logo={props.surveyInfo&&props.surveyInfo.logo&&props.surveyInfo.logo.thumbnail}
                        handleLogout={()=>handleLogout()}
                    />
                    {step==='intro'
                    ?
                        <Intro
                            handleStart={(index)=>handleStart(index)}
                        />
                    :
                        step==='do'
                        ?
                            <Do
                                apiSubmit={(key)=>apiSubmit(key)}
                                handleBack={(key)=>handleBack(key)}
                            />
                        :
                            <Result/>
                    }
                    <Footer
                        title={props.surveyInfo.name.en}
                    />
                </>
            }
            <LoadingModal/>
        </Page>
    )
}

const mapStateToProps = state => ({
    ...state.Main,
    ...state.Modal
});

const mapDispatchToProps = { setValues, setModalValues };
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Home);

const Page = styled.div`
    position: relative;
    width: 100%;
    min-height: 100vh;
    padding: 80px 0 0px;
    background: #707070;
    @media screen and (max-width: 450px){
        padding: 58px 0 0px;
    }
    .home{
        &__inner{
            width: 100%;
            background: #fff;
            @media screen and (orientation: portrait) , (max-width: 850px){
                flex-direction: column-reverse;
            }
        }
        &__detail{
            width: 100%;
            padding: 5% 6%;
            display: flex;
            flex-direction: column;
            justify-content: center;
            box-shadow: 0px 3px 6px #00000029;
            background: #F8F9FA;
            @media screen and (orientation: portrait) , (max-width: 850px){
                padding: 8%;
                text-align: center;
            }
        }
        &__detail-2{
            width: 100%;
            padding: 4% 5%;
            @media screen and (orientation: portrait) , (max-width: 850px){
                padding: 8%;
                text-align: center;
            }
        }
        &__head{
            font-family: 'Poppins-Medium', 'tahoma';
            font-size: 1.6em;
            color: #ED572C;
            line-height: 1;
            margin-bottom: 2%;
            @media screen and (orientation: portrait) , (max-width: 850px){
                margin-bottom: 5%;
            }
        }
        &__sub-head{
            font-size: 1.5em;
            color: #4E5456;
            line-height: 1;
            margin-bottom: 2%;
            @media screen and (orientation: portrait) , (max-width: 850px){
                margin-bottom: 5%;
            }
        }
        &__text{
            font-family: 'Kanit-Light', 'tahoma';
            font-size: 1em;
            color: #4E5456;
        }
        &__detail-2-inner{
            width: 100%;
            display: flex;
            align-items: inherit;
            justify-content: center;
            @media screen and (max-width: 1280px){
                flex-wrap: wrap;
                max-width: 800px;
                margin: 0 auto;
            }
        }
        &__btn{
            background: #ED592F;
            color: #fff;
            font-size: 1em;
            text-align: center;
            width: 8em;
            border-radius: 1.2em;
            padding: .5em 0;
            margin: 3% auto 0;
            display: block;
            cursor: pointer;
            transition: .3s opacity;
            &:hover{
                opacity: .9;
            }
        }
    }
`;
const Blog = styled.div`
    width: 18%;
    margin: 0 1% 2%;
    box-shadow: 0px 3px 6px #00000029;
    @media screen and (max-width: 1280px){
        width: calc(94%/3);
    }
    @media screen and (max-width: 850px){
        width: calc(96%/2);
    }
    @media screen and (max-width: 450px){
        width: calc(98%);
        margin: 0 0 10%;
    }
    .blog{
        &__img{
            width: 100%;
            padding-top: calc(100%*(282/608));
            background: url(${props=>props.src}) no-repeat center;
            background-size: cover;
        }
        &__detail{
            padding: 8% 10%;
        }
        &__head{
            font-family: 'Kanit-Regular', 'tahoma';
            color: #4E5456;
            font-size: 1em;
            text-align: center;
        }
        &__title{
            font-family: 'Poppins-Regular', 'tahoma';
            color: #4E5456;
            font-size: .9em;
            text-align: center;
        }
    }
`