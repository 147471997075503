import React,{useEffect} from 'react';
import { connect } from "react-redux";
import { setValues } from '../store/redux';
import { setValues as setModalValues } from '../features/modal/redux';
import Swal from 'sweetalert2'
import { imgList } from './../constants/ImagesList';
import {
    LoadingModal
} from '../features/modal';
import Header from "../features/header";
import Intro from "../features/intro";
import Do from "../features/do";
import Result from "../features/result";
import Footer from "../features/footer";
import styled from "styled-components";

const About = props => {
    // useEffect(() => {
    //     Swal.fire({
    //         icon: 'error',
    //         title: 'แจ้งเตือน',
    //         text: 'ไม่พบข้อมูลแบบทดสอบ',
    //         confirmButtonColor: '#0F3492',
    //     })
    // }, []);
    return (
        <>
            <Header
                name={props.userInfo.name}
                title={props.surveyInfo.name.en}
                logo={props.surveyInfo&&props.surveyInfo.logo&&props.surveyInfo.logo.thumbnail}
            />
            <Page className="home">
                <div className="home__head">
                    <div className="home__position">
                        <img className="home__imagetitle" src={"https://litabet01.sgp1.digitaloceanspaces.com/images/lita_about.png"} alt=""/>
                        <p className="home__text">Founded in 2019 and located in Europe and Asia, Lita use artificial intelligence to predict sport matches outcomes. "Data is the new gold", we use the best datas on the market in order to improve our system on a daily basis. This gives us a good understanding of the market and provide us and our clients a small edge over the market, and thus winnings on the long term.
                        </p>
                        <hr/>
                    </div>
                </div>
            </Page>
            {/*<Footer*/}
            {/*    title={props.surveyInfo.name.en}*/}
            {/*/>*/}
        </>
    )
}

const mapStateToProps = state => ({
    ...state.Main,
    ...state.Modal
});

const mapDispatchToProps = { setValues, setModalValues };
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(About);

const Page = styled.div`
    position: relative;
    width: 100%;
    min-height: 100vh;
    padding: 80px 6% 0px;
    background-image:  url(${imgList.pixel_bg}) ,linear-gradient(#08142A, #102957);
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
    hr{
        border: 1px solid #9CDCF9;
    }
    @media screen and (orientation: portrait) , (max-width: 850px){
        padding: 58px 0 0px;
        background-image: none;
        background-color: rgb(10, 28, 60);
    }
    .home{
        &__social{
            display: flex;
            justify-content: space-between;
        }
        &__position{
            text-align: center
        }
        &__imagetitle{
            margin: 0 auto;
        }
        &__inner{
            width: 100%;
            background: #fff;
            @media screen and (orientation: portrait) , (max-width: 850px){
                flex-direction: column-reverse;
            }
        }
        &__detail{
            width: 100%;
            padding: 5% 6%;
            display: flex;
            flex-direction: column;
            justify-content: center;
            box-shadow: 0px 3px 6px #00000029;
            background: #F8F9FA;
            @media screen and (orientation: portrait) , (max-width: 850px){
                padding: 8%;
                text-align: center;
            }
        }
        &__detail-2{
            width: 100%;
            padding: 4% 5%;
            @media screen and (orientation: portrait) , (max-width: 850px){
                padding: 8%;
                text-align: center;
            }
        }
        &__head{
            font-family: 'Poppins-Medium', 'tahoma';
            font-size: 1.6rem;
            color: #fff;
            line-height: 5;
            margin-bottom: 2%;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            @media screen and (orientation: portrait) , (max-width: 850px){
                margin-bottom: 5%;
            }
        }
        &__sub-head{
            font-size: 1.5em;
            color: #4E5456;
            line-height: 1;
            margin-bottom: 2%;
            @media screen and (orientation: portrait) , (max-width: 850px){
                margin-bottom: 5%;
            }
        }
        &__titletext{
            font-family: 'DB-Adman-X', 'tahoma';
            font-size: 7rem;
            color: #fff;
            letter-spacing: 2rem;
        }
        &__text{
            font-family: 'DB-Adman-X', 'tahoma';
            font-size: 1.2rem;
            color: #fff;
            line-height: 1;
            margin: 10% 0;
            @media screen and (orientation: portrait) , (max-width: 850px){
                font-size: 0.7rem;
            }
        }
        &__detail-2-inner{
            width: 100%;
            display: flex;
            align-items: inherit;
            justify-content: center;
            @media screen and (max-width: 1280px){
                flex-wrap: wrap;
                max-width: 800px;
                margin: 0 auto;
            }
        }
        &__btn{
            background: #ED592F;
            color: #fff;
            font-size: 1em;
            text-align: center;
            width: 8em;
            border-radius: 1.2em;
            padding: .5em 0;
            margin: 3% auto 0;
            display: block;
            cursor: pointer;
            transition: .3s opacity;
            &:hover{
                opacity: .9;
            }
        }
    }
`;