import React from 'react';
import styled from 'styled-components';
import { connect } from "react-redux";
import { imgList } from './../constants/ImagesList';
import Radio from './../features/radio';
import Checkbox from './../features/checkbox';

const Do = props => {
    return (
        <Page className="sur">
            <div className="sur__head">
                {props.assessmentInfo&&props.assessmentInfo.name.th} ({props.assessmentInfo&&props.assessmentInfo.name.en})
            </div>
            <div className="sur__detail">
                <div className="sur__topic">
                    <div className="sur__box">
                        {props.assessmentInfo && props.assessmentInfo.dimensions && props.assessmentInfo.dimensions.length>0 && props.assessmentInfo.dimensions.map((item, key) => {
                            return (
                                <div className={"sur__card" + (item.active?" active":"")} key={"assessment_"+key}>
                                    <img src={item.icon&&item.icon.original} alt='' className="sur__card-img"/>
                                    <div className="sur__card-text">{item.title.en}</div>
                                </div>
                            );
                        })}
                    </div>
                    <div className="sur__num-inner">  
                        {props.assessmentInfo && props.assessmentInfo.dimensions && props.assessmentInfo.dimensions.length>0 && props.assessmentInfo.dimensions.map((item, key) => {
                            return (
                                <div className={"sur__num" + (item.active?" active":"")} key={"assessment_line_"+key}>
                                    ส่วนที่ {key+1}
                                </div>
                            );
                        })}
                    </div>
                </div>
                {props.assessmentInfo && props.assessmentInfo.type==='radio'
                ?
                    <Radio/>
                :
                    <Checkbox/>
                }
                <div className="sur__btn-group">
                    {props.assessmentInfo&&props.assessmentInfo.dimension===1
                    ?
                        <div className="sur__btn sur__btn--gray">
                            Back
                        </div>
                    :
                        <div className="sur__btn sur__btn--gray" onClick={()=>props.handleBack(props.assessmentInfo&&props.assessmentInfo.dimension)}>
                            Back
                        </div>
                    }
                    {props.assessmentInfo&&props.assessmentInfo.dimension<props.surveyInfo.dimensions_count
                    ?
                        <div className="sur__btn sur__btn--primary" onClick={()=>props.apiSubmit(props.assessmentInfo&&props.assessmentInfo.dimension)}>
                            Next
                        </div>
                    :
                        <div className="sur__btn sur__btn--primary" onClick={()=>props.apiSubmit(props.assessmentInfo&&props.assessmentInfo.dimension)}>
                            Submit
                        </div>
                    }
                </div>
                <div className="sur__page">PAGE: {props.assessmentInfo&&props.assessmentInfo.dimension} / {props.surveyInfo.dimensions_count}</div>
            </div>
        </Page>
    )
}

const mapStateToProps = state => ({
    ...state.Main,
    ...state.Modal
});

export default connect(
    mapStateToProps
)(Do);

const Page = styled.div`
    width: 100%;
    background: #fff;
    .sur{
        &__inner{
            width: 100%;
            background: #fff;
        }
        &__head{
            width: 100%;
            padding: 5% 6% 12%;
            color: #0F3492;
            font-size: 1.8em;
            background: #F8F9FA;
            box-shadow: 0px 3px 6px #00000029;
            text-align: center;
            font-family: 'Kanit-Medium', 'tahoma';
        }
        &__detail{
            position: relative;
            width: 100%;
            padding: 12% 6% 5%;
            background: #fff;
        }
        &__topic{
            width: 100%;
            margin-top: -25%;
            margin-bottom: 4%;
        }
        &__box{
            width: 100%;
            display: flex;
            align-items: inherit;
            justify-content: center;
        }
        &__card{
            padding: 2%;
            width: 19%;
            margin: 0 .5%;
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
            background: url(${imgList.rec}) no-repeat center;
            background-size: 100% 100%;
            @media screen and (max-width: 850px){
                display: none;
                &.active{
                    width: 100%;
                    max-width: 300px;
                    margin: 0 auto;
                    display: flex;
                    padding: 5%;
                }
            }
        }
        &__card-img{
            width: 50%;
            margin-bottom: 3%;
        }
        &__card-text{
            font-family: 'Kanit-Regular', 'tahoma';
            font-size: 1em;
            color: #4E5456;
            width: 8em;
            text-align: center;
            white-space: break-spaces;
        }
        &__num-inner{
            width: 100%;
            display: flex;
            align-items: inherit;
            justify-content: center;
        }
        &__num{
            width: 18%;
            margin: 0 1%;
            text-align: center;
            font-size: 1em;
            color: #4E5456;
            font-family: 'Kanit-Regular', 'tahoma';
            padding: .5em 0;
            &.active{
                border-bottom: .2em solid #0F3492;
            }
            @media screen and (max-width: 850px){
                display: none;
                &.active{
                    width: 100%;
                    display: block;
                    border-bottom: none;
                    font-size: 1.2em;
                }
            }
        }
        &__btn-group{
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            margin-top: 6%;
        }
        &__btn{
            color: #fff;
            font-size: .8em;
            text-align: center;
            width: 8em;
            border-radius: 1.2em;
            padding: .5em 0;
            margin: 0 1%;
            font-family: 'Kanit-Regular', 'tahoma';
            cursor: pointer;
            transition: .3s opacity;
            &:hover{
                opacity: .9;
            }
            // @media screen and (orientation: portrait) , (max-width: 850px){
            //     margin: 0 auto;
            // }
            &--gray{
                background: #4E5456;
            }
            &--primary{
                background: #ED592F;
            }
        }
        &__page{
            font-family: 'Poppins-Medium', 'tahoma';
            font-size: .7em;
            color: #707070;
            text-align: center;
            margin-top: 1%;
        }
    }
`;