import banner_login from '../static/images/banner_login.png';
import logo from '../static/images/logo.png';
import icon_hamberger from '../static/images/coolicon.png';
import icon_user from '../static/images/icon_user.png';
import icon_close from '../static/images/icon_close.png';
import home_mock_5 from '../static/images/home_mock_5.png';
import home_mock_4 from '../static/images/home_mock_4.png';
import home_mock_3 from '../static/images/home_mock_3.png';
import home_mock_2 from '../static/images/home_mock_2.png';
import home_mock_1 from '../static/images/home_mock_1.png';
import result_mock_1 from '../static/images/result_mock_1.png';
import result_mock_2 from '../static/images/result_mock_2.png';
import result_mock_3 from '../static/images/result_mock_3.png';
import result_mock_4 from '../static/images/result_mock_4.png';
import result_mock_5 from '../static/images/result_mock_5.png';
import pin from '../static/images/pin.png';
import rec from '../static/images/rec.png';
import btn_radio from '../static/images/btn_radio.png';
import btn_checkbox from '../static/images/btn_checkbox.png';
import logoexam from '../static/images/logoexam.png';
import bg from '../static/images/BG.png';
import btn_active from '../static/images/btn_active.png';
import pixel_bg from '../static/images/pixelbg.png';
import twitter from '../static/images/twitter.png';
import telegram from '../static/images/telegram.png';
import whatsapp from '../static/images/whatsapp.png';
import line from '../static/images/line.png';
import lita_about from '../static/images/lita_about.png';
import gif_first from '../static/gif/MIX_final.gif';
import mock_blog from '../static/images/mock_blog.png';
import demo from '../static/images/demo.png';
import bghome_mobile from '../static/images/bghome_mobile.png';

export const imgList = {
    banner_login,
    logo,
    icon_hamberger,
    icon_user,
    icon_close,
    home_mock_5,
    home_mock_4,
    home_mock_3,
    home_mock_2,
    home_mock_1,
    result_mock_1,
    result_mock_2,
    result_mock_3,
    result_mock_4,
    result_mock_5,
    pin,
    rec,
    btn_radio,
    btn_checkbox,
    logoexam,
    bg,
    btn_active,
    pixel_bg,
    twitter,
    telegram,
    whatsapp,
    line,
    lita_about,
    gif_first,
    mock_blog,
    demo,
    bghome_mobile,
}
