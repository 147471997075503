import { createStore, combineReducers, applyMiddleware, compose } from 'redux';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage'; // defaults to localStorage for web and AsyncStorage for react-native
import thunk from 'redux-thunk';

// logger
import { logger } from './../utils/logger';
// Redux-promise middleware
import promise from 'redux-promise-middleware';
// Redux Reducers
import { MainReducer } from './redux';
import { ModalReducer } from './../features/modal/redux';

const persistConfig = {
  key: 'root',
  storage,
  // blacklist: ['Main','Home','Modal'],// disable persist
  whitelist: []
};

const middleware = [thunk, logger, promise];
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const persistedReducer = persistReducer(
  persistConfig,
  // MainReducer
  combineReducers({
    Main: MainReducer,
    Modal: ModalReducer
  })
);

export default () => {
  let store = createStore(persistedReducer, composeEnhancers(applyMiddleware(...middleware)));
  let persistor = persistStore(store);
  return { store, persistor };
};
