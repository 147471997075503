import React,{useEffect} from 'react';
import { connect } from "react-redux";
import { setValues } from '../store/redux';
import { setValues as setModalValues } from '../features/modal/redux';
import Swal from 'sweetalert2'
import LinesEllipsis from 'react-lines-ellipsis'
import Moment from 'moment';

import { imgList } from './../constants/ImagesList';
import {
    LoadingModal
} from '../features/modal';
import Header from "../features/header";
import Intro from "../features/intro";
import Do from "../features/do";
import Result from "../features/result";
import Footer from "../features/footer";
import styled from "styled-components";
import {Link} from "react-router-dom";

const Blog = props => {
    useEffect(() => {
        apiBlogs();
    }, []);

    const apiBlogs = () =>{
        fetch(process.env.REACT_APP_API_SERVER_HOST + process.env.REACT_APP_API_BLOGS, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json'
            }
        }).then(response =>{
            if (response.status == 200) {
                response.json().then(response => {
                    console.log(response)
                    if(response.status){
                        props.setValues({
                            blogs:response.data
                        })
                    }
                });
            }
            else{
                console.log('API Error Network: ', response.error)
                Swal.fire({
                    icon: 'error',
                    title: 'แจ้งเตือน',
                    text: 'เกิดข้อผิดพลาด กรุณาลองใหม่อีกครั้ง',
                    confirmButtonColor: '#0F3492',
                })
            }
        }).catch(error => {
            console.log('API Error : ', error)
        })
    }

    return (
        <>
            <Header
                name={props.userInfo.name}
                title={props.surveyInfo.name.en}
                logo={props.surveyInfo&&props.surveyInfo.logo&&props.surveyInfo.logo.thumbnail}
            />
            <Page className="home">
               <div className="col-md-12 home__layout">
                   <div className="row">
                       {
                           props.blogs && props.blogs.length > 0 &&
                           props.blogs.map((value,key) => {
                               return (
                                   <div className="col-md-4">
                                       <div className="home__blog">
                                           <div className="home__blog--title">{value.title}</div>
                                           <div className="home__blog--date">{Moment(value.created_at).format('DD-MM-YYYY')} | {value.writer}</div>
                                           <img className="home__blog--img" src={value.image} alt=""/>
                                           <div className="home__blog--content"><LinesEllipsis
                                               text={value.content}
                                               maxLine='3'
                                               ellipsis='...'
                                               trimRight
                                               basedOn='letters'
                                           /></div>
                                           <a href={process.env.REACT_APP_EVENT_SLUG_BLOG_PATH + value.slug} className="btn home__blog--btn">READ MORE</a>
                                       </div>
                                   </div>
                               )
                           })
                       }

                   </div>

               </div>
            </Page>
            {/*<Footer*/}
            {/*    title={props.surveyInfo.name.en}*/}
            {/*/>*/}
        </>
    )
}

const mapStateToProps = state => ({
    ...state.Main,
    ...state.Modal
});

const mapDispatchToProps = { setValues, setModalValues };
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Blog);

const Page = styled.div`
    position: relative;
    width: 100%;
    min-height: 100vh;
    padding: 80px 6% 0px;
    background-color: #0A1C3C;
    font-family: 'DB-Adman-X', 'tahoma';
    hr{
        border: 1px solid #9CDCF9;
    }
    @media screen and (max-width: 450px){
        padding: 58px 0 0px;
    }
    .home{
        &__layout {
            margin-top: 5%;
            @media screen and (max-width: 450px){
                overflow-y: auto;
                height: 85vh
            }
        }
        &__blog{
            margin-bottom: 5%;
           &--title{
                font-size: 2rem;
                color: #fff;
           }
           &--date{
                font-size: 1rem;
                color: #1672FC;
           }
           &--img{
                width: 100%;
                margin:2% 0;
           }
           &--content{
               font-size: 1rem;
               color: #fff;
           }
           &--btn{
                margin: 3% 0;
                color: #fff;
                border: 1px solid #7DB1FF;
                border-radius: 20px;
                letter-spacing: 0.2rem;
           }
        }
        &__content{
            padding: 2%;
        }
        &__social{
            display: flex;
            justify-content: space-between;
        }
        &__position{
            text-align: center
        }
        &__imagetitle{
            margin: 0 auto;
        }
        &__inner{
            width: 100%;
            background: #fff;
            @media screen and (orientation: portrait) , (max-width: 850px){
                flex-direction: column-reverse;
            }
        }
        &__detail{
            width: 100%;
            padding: 5% 6%;
            display: flex;
            flex-direction: column;
            justify-content: center;
            box-shadow: 0px 3px 6px #00000029;
            background: #F8F9FA;
            @media screen and (orientation: portrait) , (max-width: 850px){
                padding: 8%;
                text-align: center;
            }
        }
        &__detail-2{
            width: 100%;
            padding: 4% 5%;
            @media screen and (orientation: portrait) , (max-width: 850px){
                padding: 8%;
                text-align: center;
            }
        }
        &__head{
            font-family: 'Poppins-Medium', 'tahoma';
            font-size: 1.6rem;
            color: #fff;
            line-height: 5;
            margin-bottom: 2%;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            @media screen and (orientation: portrait) , (max-width: 850px){
                margin-bottom: 5%;
            }
        }
        &__sub-head{
            font-size: 1.5em;
            color: #4E5456;
            line-height: 1;
            margin-bottom: 2%;
            @media screen and (orientation: portrait) , (max-width: 850px){
                margin-bottom: 5%;
            }
        }
        &__titletext{
            font-family: 'DB-Adman-X', 'tahoma';
            font-size: 7rem;
            color: #fff;
            letter-spacing: 2rem;
        }
        &__text{
            font-family: 'DB-Adman-X', 'tahoma';
            font-size: 1rem;
            color: #fff;
            line-height: 1;
            margin: 10% 0;
            @media screen and (orientation: portrait) , (max-width: 850px){
                font-size: 0.7rem;
            }
        }
        &__detail-2-inner{
            width: 100%;
            display: flex;
            align-items: inherit;
            justify-content: center;
            @media screen and (max-width: 1280px){
                flex-wrap: wrap;
                max-width: 800px;
                margin: 0 auto;
            }
        }
        &__btn{
            background: #ED592F;
            color: #fff;
            font-size: 1em;
            text-align: center;
            width: 8em;
            border-radius: 1.2em;
            padding: .5em 0;
            margin: 3% auto 0;
            display: block;
            cursor: pointer;
            transition: .3s opacity;
            &:hover{
                opacity: .9;
            }
        }
    }
`;