import React,{useEffect,useState} from 'react';
import styled from 'styled-components';
import { connect } from "react-redux";
import { imgList } from './../constants/ImagesList';
import {Link} from "react-router-dom";

export default (props) => {
    const [mobile, setMobile] = useState(false);
    const handleOpen = () =>{
        document.body.style.overflowY = "hidden";
        setMobile(true)
    }
    const handleClose= () =>{
        document.body.style.overflowY = "unset";
        setMobile(false)
    }
    return (
        <Content className="header">
            <div className="header__left">
                <img src={imgList.logo} alt='' className="header__logo"/>
                {props.logo&&
                    <img src={props.logo} alt='' className="header__logo-sub"/>
                }
                {/*<div className="header__head">{props.title}</div>*/}
            </div>
            <div className="header__right">
                <div className="header__user">
                    <a href={"/"}><div className="header__bgHover">HOME</div></a>
                </div>
                <div className="header__user">
                    <a href={"/blog"}><div className="header__bgHover">BLOG</div></a>
                </div>
                <div className="header__user">
                    <Link to={"/about"}><div className="header__bgHover">ABOUT</div></Link>
                </div>
                <div className="header__user">
                    <Link to={"/contact"}><div className="header__bgHover">CONTACT</div></Link>
                </div>
            </div>
            <img src={imgList.icon_hamberger} alt='' className="header__ham" onClick={()=>handleOpen()}/>
            <div className={"header__mobile-warp" + (mobile?" show":"")} onClick={()=>handleClose()}></div>
            <div className={"header__mobile-inner" + (mobile?" show":"")}>
                <img src={imgList.icon_close} alt='' className="header__close" onClick={()=>handleClose()}/>
                <div className="header__text">{props.title}</div>
                {props.page!=='login' &&
                    <>
                        <div className="header__mobile-text header__mobile-text--pointer">
                            <div><Link to={"/"}>HOME</Link></div>
                        </div>
                        <div className="header__mobile-text header__mobile-text--pointer">
                            <div><Link to={"/blog"}>BLOG</Link></div>
                        </div>
                        <div className="header__mobile-text header__mobile-text--pointer">
                            <div><Link to={"/about"}>ABOUT</Link></div>
                        </div>
                        <div className="header__mobile-text header__mobile-text--pointer">
                            <div><a href={"/contact"}>CONTACT</a></div>
                        </div>
                    </>
                }
            </div>
        </Content>
    )
}

const Content = styled.div`
    position: fixed;
    top: 0;
    width: 100%;
    height: 80px;
    background: linear-gradient(#08142A, #102957);
    // box-shadow: 0px 3px 6px #00000029;
    font-family: 'DB-Adman-X', 'tahoma';
    padding: 0 6%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    z-index: 10;
    @media screen and (max-width: 450px){
        height: 58px;
    }
    .header{
        &__bgHover{
            padding: 1rem 1.5rem;
            font-size: 1rem;
            &:hover {
                background-image: url(${imgList.btn_active});
                background-size: contain;
                background-repeat: no-repeat;
                background-position: center;
                font-size: 1rem;
            }
        }
        &__logo{
            height: 50%;
        }
        &__logo-sub{
            height: 52%;
            border-left: 1px solid #707070;
            padding-left: 1em;
            margin-left: 1em;
        }
        &__head{
            font-family: 'Poppins-Medium', 'tahoma';
            color: #ffff;
            font-size: 1.2em;
            border-left: 1px solid #707070;
            height: 52%;
            display: flex;
            align-items: center;
            padding-left: 1em;
            margin-left: 1em;
            line-height: 1;
            @media screen and (orientation: portrait) , (max-width: 850px){
                display: none;
            }
            // @media screen and (orientation: portrait) and (max-width: 450px){
            //     font-size: .8em;
            // }
            // @media screen and (orientation: portrait) and (max-width: 330px){
            //     font-size: .7em;
            // }
        }
        &__text{
            font-family: 'DB-Adman-X','tahoma';
            color: #ffff;
            font-size: .8em;
            text-align: left;
            line-height: 1.2;
        }
        &__left{
            height: 100%;
            display: flex;
            align-items: center;
            @media screen and (orientation: portrait) , (max-width: 850px){
                margin: 0 auto;
            }
        }
        &__right{
            height: 100%;
            display: flex;
            align-items: center;
            @media screen and (orientation: portrait) , (max-width: 850px){
                display: none;
            }
        }
        &__user{
            color: #fff;
            height: 100%;
            display: flex;
            align-items: center;
            margin-right: 2em;
            >img{
                height: 45%;
                display: block;
                margin-right: .5em;
            }
            >div{
                font-family: 'DB-Adman-X', 'tahoma';
                color: #ffff;
                font-size: .8em;
                line-height: 1;
            }
            // &:hover{
            //     background-image: url(${imgList.btn_active});
            // }
        }
        &__logout{
            font-family: 'DB-Adman-X', 'tahoma';
            color: #ffff;
            font-size: .8em;
            cursor: pointer;
        }
        &__ham{
            position: absolute;
            display: none;
            height: 25%;
            cursor: pointer;
            @media screen and (orientation: portrait) , (max-width: 850px){
                display: block;
            }
        }
        &__mobile-warp{
            position: fixed;
            top: 0;
            left: 0;
            width: 100vw;
            height: 100vh;
            background-color: rgba(0, 0, 0, 0.5);
            opacity: 0;
            pointer-events: none;
            transition: .3s opacity;
            &.show{
                opacity: 1;
                pointer-events: all;
            }
        }
        &__mobile-inner{
            position: fixed;
            top: 0;
            left: -75%;
            width: 70vw;
            height: 100vh;
            padding: 1.2em;
            background-color: #fff;
            box-shadow: 0px 8px 10px -5px rgba(0,0,0,0.2), 0px 16px 24px 2px rgba(0,0,0,0.14), 0px 6px 30px 5px rgba(0,0,0,0.12);
            transition: .3s left;
            &.show{
                left: 0%;
            }
        }
        &__mobile-text{
            width: 100%;
            display: flex;
            align-items: center;
            padding: .5em 0;
            margin-top: .5em;
            border-bottom: .1em solid #707070;
            >img{
                width: 1.2em;
                display: block;
                margin-right: .5em;
            }
            >div{
                font-family: 'DB-Adman-X', 'tahoma';
                color: black;
                font-size: 1.2em;
                line-height: 1;
            }
            &--pointer{
                cursor: pointer;
            }
        }
        &__close{
            position: absolute;
            display: block;
            width: 1em;
            top: 1%;
            right: 2%;
            cursor: pointer;
        }
    }
`;

