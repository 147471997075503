import React,{useEffect,useState} from 'react';
import styled from 'styled-components';
import { connect } from "react-redux";
import { imgList } from './../constants/ImagesList';
import { CircularProgressbar,buildStyles } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';

import {Radar} from 'react-chartjs-2';

const Result = props => {
    const [bi, setBi] = useState([]);
    // useEffect(() => {
    //     let a = [];
    //     let color = [
    //         '15,52,146',
    //         '105,162,251',
    //         '0,124,114',
    //         '0,75,124',
    //         '0,124,73',
    //         '29,0,124',
    //         '0,124,209',
    //         '0,209,85',
    //         '68,0,209',
    //         '0,4,209',
    //         '15,52,146',
    //         '105,162,251',
    //         '15,52,146',
    //         '105,162,251',
    //         '15,52,146',
    //         '105,162,251',
    //         '15,52,146',
    //         '105,162,251',
    //         '15,52,146',
    //         '105,162,251',
    //         '15,52,146',
    //         '105,162,251',
    //         '15,52,146',
    //         '105,162,251',
    //         '15,52,146',
    //         '105,162,251',
    //         '15,52,146',
    //         '105,162,251',
    //         '15,52,146',
    //         '105,162,251',
    //         '15,52,146',
    //         '105,162,251',
    //         '15,52,146',
    //         '105,162,251',
    //         '15,52,146',
    //         '105,162,251',
    //         '15,52,146',
    //         '105,162,251',
    //         '15,52,146',
    //         '105,162,251',
    //         '15,52,146',
    //         '105,162,251',
    //         '15,52,146',
    //         '105,162,251',
    //         '15,52,146',
    //         '105,162,251',
    //         '15,52,146',
    //         '105,162,251'
    //     ];
    //     props.resultInfo.overall.graph.result_bi_arr.length>0&&props.resultInfo.overall.graph.result_bi_arr.map((item, key) => {
    //         // console.log(color[key]);
    //         let b = {
    //             label: props.resultInfo.overall.graph.bi_name[key],
    //             backgroundColor: 'rgba(255,255,255,0.1)',
    //             borderColor: 'rgba('+color[key]+',1)',
    //             pointBackgroundColor: 'rgba(255,188,0,1)',
    //             pointBorderColor: '#fff',
    //             pointHoverBackgroundColor: '#fff',
    //             pointHoverBorderColor: 'rgba(255,188,0,1)',
    //             data: item
    //         }
    //         a = [
    //             ...a,
    //             b
    //         ]
    //     })
    //     setBi(a);
    // }, [props.resultInfo.overall.graph]);
    // const data = {
    //     labels: props.resultInfo&&props.resultInfo.overall.graph.dimensions_name,
    //     datasets: bi
    // };
    const options = {
        scale: {
            ticks: {
                beginAtZero: true,
                max: 100,
                min: 0
            }
        }
    }
    const mock = [
        {
            img: imgList.result_mock_1,
            head: 'Customer Centricity',
            data: ['ส่วนที่ 1 การคำนึงถึงลูกค้าเป็นสำคัญ','การตอบสนองความต้องการของกลุ่มนักท่องเที่ยวเฉพาะตัว','การสร้างสรรค์เนื้อหาในยุคดิจิทัล','การสร้างสรรค์เนื้อหาในยุคดิจิทัล']
        },
        {
            img: imgList.result_mock_2,
            head: 'Digital Strategy & Operation',
            data: ['ส่วนที่ 1 การคำนึงถึงลูกค้าเป็นสำคัญ','การตอบสนองความต้องการของกลุ่มนักท่องเที่ยวเฉพาะตัว','การสร้างสรรค์เนื้อหาในยุคดิจิทัล','การสร้างสรรค์เนื้อหาในยุคดิจิทัล']
        },
        {
            img: imgList.result_mock_3,
            head: 'Data Driven',
             data: ['ส่วนที่ 1 การคำนึงถึงลูกค้าเป็นสำคัญ','การตอบสนองความต้องการของกลุ่มนักท่องเที่ยวเฉพาะตัว','การสร้างสรรค์เนื้อหาในยุคดิจิทัล','การสร้างสรรค์เนื้อหาในยุคดิจิทัล']
        },
        {
            img: imgList.result_mock_4,
            head: 'Technology Driven',
             data: ['ส่วนที่ 1 การคำนึงถึงลูกค้าเป็นสำคัญ','การตอบสนองความต้องการของกลุ่มนักท่องเที่ยวเฉพาะตัว','การสร้างสรรค์เนื้อหาในยุคดิจิทัล','การสร้างสรรค์เนื้อหาในยุคดิจิทัล']
        },
        {
            img: imgList.result_mock_5,
            head: 'Technology Driven',
             data: ['ส่วนที่ 1 การคำนึงถึงลูกค้าเป็นสำคัญ','การตอบสนองความต้องการของกลุ่มนักท่องเที่ยวเฉพาะตัว','การสร้างสรรค์เนื้อหาในยุคดิจิทัล','การสร้างสรรค์เนื้อหาในยุคดิจิทัล']
        }
    ]
    // console.log(data);
    return (
        <Content>
            <div className="content__head">
                ผลการประเมิน{props.surveyInfo.name.th}<br/>({props.surveyInfo.name.en})
            </div>
            <div className="content__detail">
                <div className="content__detail-inner">
                    <div className="content__card">
                        <div className="content__title">
                            รายละเอียดผู้ประเมิน
                        </div>
                        <div className="content__user">
                            <img src={imgList.icon_user} alt='' className="content__user-img"/>
                            <div className="content__user-detail">
                                <div className="content__row">
                                    <div>ชื่อ: {props.resultInfo&&props.resultInfo.full_name}</div>
                                </div>
                                <div className="content__row">
                                    <div>ตำแหน่ง: {props.resultInfo&&props.resultInfo.position}</div>
                                </div>
                                <div className="content__row content__row--last">
                                    <div>หน่วยงาน: {props.resultInfo&&props.resultInfo.department}</div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* <div className="content__card">
                        <div className="content__title">
                            ทักษะที่โดดเด่น
                        </div>
                        <div className="content__text-span">
                            คุณมีความโดดเด่นในทักษะด้านดิจิทัล (Digital Skill) ใน<span>สมรรถนะ บุคลากรและวัฒนธรรมองค์กร</span>
                        </div>
                    </div> */}
                </div>
                <div className="content__detail-inner">
                    <div className="content__card">
                        <div className="content__title">
                            ภาพรวมการประเมิน
                        </div>
                        <CircularProgressbar 
                            className="content__progress"
                            value={props.resultInfo&&props.resultInfo.overall.percent} 
                            text={props.resultInfo&&props.resultInfo.overall.percent+"%"} 
                            styles={buildStyles({
                                pathColor: '#0F3492',
                                textColor: '#000',
                                trailColor: '#fff',
                                backgroundColor: '#fff',
                            })}
                        />
                        <div className="content__score-head">
                            คุณได้คะแนนคิดเป็น {props.resultInfo&&props.resultInfo.overall.percent}%
                        </div>
                        <div className="content__score-title">
                            {props.resultInfo&&props.resultInfo.overall.text}
                        </div>
                    </div>
                    {/* <div className="content__card content__card--last">
                        <div className="content__title">
                            แสดงผลการประเมินรายสมรรถนะ
                        </div>
                        <Radar data={data} options={options}/>
                    </div> */}
                </div>
            </div>
            <div className="content__head-2">
                รายละเอียดผลการประเมิน
            </div>
            <div className="content__detail-2">
                {props.resultInfo&&props.resultInfo.overall.dimensions.length>0&&props.resultInfo.overall.dimensions.map((item, key) => {
                    return (
                        <div className="content__detail-inner-2" key={"result_head_"+key}>
                            <div className="content__left">
                                <img src={item.icon} alt='' className="content__left-img"/>
                                <div className="content__left-text">{item.name.en}</div>
                            </div>
                            <div className="content__right">
                                <div className="content__right-row">
                                    <div className="content__right-title content__right-title--head">ส่วนที่ {item.order} {item.name.th}</div>
                                    <div className="content__right-progress">
                                        <div className="content__progress-left">
                                            0%
                                        </div>
                                        <div className="content__progress-right">
                                            100%
                                        </div>
                                        <img src={imgList.pin} alt='' className="content__pin" style={{ left: item.all_percent + '%' }}/>
                                    </div>
                                </div>
                                {item.topics&&item.topics.length>0&&item.topics.map((item_data, key_data) => {
                                    return (
                                        <div className={"content__right-row" + (item.topics.length===key_data+1?" content__right-row--last":"")} key={"result_sub-head_"+key_data}>
                                            <div className="content__right-title">{item_data.name}</div>
                                            <div className="content__right-progress">
                                                <div className="content__progress-left">
                                                    0%
                                                </div>
                                                <div className="content__progress-right">
                                                    100%
                                                </div>
                                                <img src={imgList.pin} alt='' className="content__pin" style={{ left: item_data.percent + '%' }}/>
                                            </div>
                                        </div>
                                    );
                                })}
                            </div>
                        </div>
                    );
                })}
            </div>
        </Content>
    )
}

const mapStateToProps = state => ({
    ...state.Main,
    ...state.Modal
});

export default connect(
    mapStateToProps
)(Result);

const Content = styled.div`
    width: 100%;
    background: #fff;
    .content{
        &__head{
            width: 100%;
            // padding: 5% 6%;
            padding: 4% 6%;
            // color: #0F3492;
            font-size: 1.6em;
            // background: #F8F9FA;
            color: #fff;
            background: #0F3492;
            box-shadow: 0px 3px 6px #00000029;
            text-align: center;
            font-family: 'Kanit-Medium', 'tahoma';
        }
        &__head-2{
            width: 100%;
            padding: 1% 6%;
            color: #0F3492;
            font-size: 1.5em;
            background: #F8F9FA;
            box-shadow: 0px 3px 6px #00000029;
            text-align: center;
            font-family: 'Kanit-Medium', 'tahoma';
        }
        &__detail{
            width: 100%;
            padding: 5% 6%;
            @media screen and (max-width: 850px){
                padding: 6%;
            }
        }
        &__detail-2{
            width: 100%;
            padding: 5% 6%;
            @media screen and (max-width: 850px){
                padding: 6%;
            }
        }
        &__detail-inner{
            width: 100%;
            display: flex;
            align-items: inherit;
            justify-content: center;
            flex-wrap: wrap;
        }
        &__detail-inner-2{
            width: 100%;
            display: flex;
            align-items: inherit;
            justify-content: center;
            flex-wrap: wrap;
            margin-bottom: 3%;
            @media screen and (max-width: 850px){
                margin-bottom: 6%;
            }
        }
        &__card{
            width: 48%;
            padding: 2%;
            margin: 0 1% 2%;
            background: #fff;
            box-shadow: 0px 3px 6px #00000029;
            &--last{
                margin: 0 1% 0%;
            }
            @media screen and (max-width: 850px){
                width: 100%;
                padding: 6%;
                margin: 0 0 4%;
                &--last{
                    margin: 0;
                }
            }
        }
        &__title{
            color: #0F3492;
            font-size: 1.4em;
            text-align: center;
            margin-bottom: 3%;
            font-family: 'Kanit-Medium', 'tahoma';
            line-height: 1;
        }
        &__user{
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: space-between;
        }
        &__user-img{
            width: 15%;
        }
        &__user-detail{
            width: 80%;
        }
        &__row{
            display: flex;
            align-items: center;
            margin-bottom: 2%;
            >div{
                font-family: 'Kanit-Medium', 'tahoma';
                color: #707070;
                font-size: 1.1em;
                line-height: 1;
                // &:first-child{
                //     width: 5em;
                // }
                // &:last-child{
                //     width: 10em;
                // }
            }
            &--last{
                margin-bottom: 0%;
            }
        }
        &__text-span{
            font-family: 'Kanit-Medium', 'tahoma';
            color: #707070;
            font-size: 1.1em;
            >span{
                color: #000000;
            }
        }
        &__score-head{
            font-family: 'Kanit-Medium', 'tahoma';
            color: #000000;
            font-size: 1.2em;
            text-align: center;
        }
        &__score-title{
            font-family: 'Kanit-Medium', 'tahoma';
            color: #707070;
            font-size: 1.1em;
            text-align: center;
        }
        &__progress{
            width: 30%;
            margin: 0 auto;
            display: block;
            .CircularProgressbar-text{
                font-size: .8em;
                font-family: 'Kanit-Medium', 'tahoma';
            }
            @media screen and (max-width: 850px){
                margin: 5% auto;
            }
        }
        &__left{
            box-shadow: 0px 3px 6px #00000029;
            padding: 2%;
            width: 20%;
            margin: 0 1%;
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
            @media screen and (max-width: 850px){
                width: 100%;
                margin: 0 0 4%;
                padding: 5%;
            }
        }
        &__right{
            box-shadow: 0px 3px 6px #00000029;
            padding: 3% 5%;
            width: 76%;
            margin: 0 1%;
            @media screen and (max-width: 850px){
                width: 100%;
                margin: 0;
                padding: 5%;
            }
        }
        &__left-img{
            width: 50%;
            margin-bottom: 3%;
        }
        &__left-text{
            font-family: 'Kanit-Regular', 'tahoma';
            font-size: 1em;
            color: #4E5456;
            width: 8em;
            text-align: center;
        }
        &__right-row{
            width: 100%;
            display: flex;
            align-items: flex-start;
            margin-bottom: 4%;
            flex-wrap: wrap;
            &--last{
                margin-bottom: 0%;
            }
        }
        &__right-title{
            // width: 68%;
            // margin-right: 3%;
            width: 55%;
            margin-right: 16%;
            color: #707070;
            font-size: 1em;
            font-family: 'Kanit-Medium', 'tahoma';
            &--head{
                font-size: 1.1em;
                color: #000;
            }
            @media screen and (max-width: 550px){
                width: 100%;
                margin-right: 0;
                text-align: center;
            }
        }
        &__right-progress{
            position: relative;
            width: 25%;
            height: 1em;
            background: #B8B8B8;
            @media screen and (max-width: 550px){
                width: 50%;
                margin: 15% auto 10%;
            }
        }
        &__progress-left{
            position: absolute;
            color: #707070;
            left: -1.5em;
            top: 50%;
            transform: translate(0, -50%);
            font-size: .8em;
            font-family: 'Kanit-Medium', 'tahoma';
        }
        &__progress-right{
            position: absolute;
            color: #707070;
            right: -2.5em;
            top: 50%;
            transform: translate(0, -50%);
            font-size: .8em;
            font-family: 'Kanit-Medium', 'tahoma';
        }
        &__pin{
            position: absolute;
            width: 2em;
            max-width: unset;
            max-height: unset;
            top: -150%;
            left: 50%;
            transform: translate(-50%, 0);
        }
    }
`